.table-tool-icon {
  height: 22px;
  width: 22px;
  color: #757575;
}

.table-tool-icon:hover {
  background-color: rgba(227, 233, 239, 0.44);
  color: black;
}

.nav-bar-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.search-icon {
  height: 20px;
  width: 20px;
  color: #c2c2c2;
}
